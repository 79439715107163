
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('span', { 'className': 'icon-search--desktop' }, 'Search'), _createElement('svg', {
                'id': 'Layer_1',
                'className': 'icon icon-header-search icon-search--mobile',
                'data-name': 'Layer 1',
                'xmlns': 'http://www.w3.org/2000/svg',
                'viewBox': '0 0 980.04 981.7'
            }, _createElement('g', {
                'id': '_3_3',
                'data-name': '3_3'
            }, _createElement('path', {
                'd': 'M979.3,928.4,718.5,667.2a399.9,399.9,0,0,0,92.8-256.7C811.3,188.9,631.9,9.2,410.7,9.2S10,188.9,10,410.5,189.4,811.8,410.7,811.8A398.56,398.56,0,0,0,667,718.9L927.8,980.2a36.52,36.52,0,1,0,51.5-51.8ZM410.7,738.7C230,738.7,83,591.4,83,410.5S230,82.3,410.7,82.3,738.4,229.5,738.4,410.5,591.3,738.7,410.7,738.7Z',
                'transform': 'translate(-10 -9.2)'
            }))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]