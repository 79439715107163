
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('span', { 'className': 'products' }, _createElement('div', { 'className': 'card-wrapper' }, _createElement('div', { 'className': 'card-img' }, _createElement('a', {
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, _createElement('div', {
                    'className': 'card card--product',
                    'tabIndex': '-1'
                }, _createElement('div', { 'className': 'card__inner' }, _createElement('div', {}, _createElement('div', {
                    'className': 'media media--transparent media--adapt media--hover-effect',
                    'style': { paddingBottom: '100%' }
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'loading': 'lazy',
                    'className': 'motion-reduce',
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'loading': 'lazy',
                    'className': 'motion-reduce',
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '559'
                }) : null))))), _createElement('div', { 'className': 'product-hover' }, _createElement('button', {
                    'type': 'button',
                    'aria-label': 'Add to wishlist',
                    'className': 'cm-link btn--status' + (getWishlist?.().includes(this.handle) ? ' active' : ''),
                    'data-product-handle': this.handle,
                    'onClick': e => {
                        updateWishlist?.(this.handle);
                        e.target.classList.toggle('active');
                    }
                }, [_createElement('svg', {
                        'version': '1.1',
                        'className': 'icon icon-cmheart not-added',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                        'x': '0px',
                        'y': '0px',
                        'viewBox': '0 0 1000 1000',
                        'enableBackground': 'new 0 0 1000 1000',
                        'xmlSpace': 'preserve',
                        'key': '11870'
                    }, _createElement('g', {}, _createElement('path', { 'd': 'M990,335.9c0,80.6-41.8,162.6-125.3,246.1L524.1,910.2c-6.6,6.5-14.6,9.8-24.1,9.8c-9.5,0-17.5-3.3-24.1-9.8L134.7,581c-3.7-2.9-8.7-7.7-15-14.2c-6.4-6.6-16.5-18.5-30.4-35.8c-13.9-17.3-26.3-35.1-37.2-53.3s-20.7-40.3-29.3-66.2C14.2,385.5,10,360.4,10,335.9c0-80.2,23.1-142.9,69.5-188.1c46.3-45.2,110.3-67.8,192-67.8c22.6,0,45.7,3.9,69.2,11.7c23.5,7.9,45.4,18.4,65.6,31.7s37.6,25.8,52.2,37.4c14.5,11.7,28.4,24.1,41.5,37.2c13.1-13.1,26.9-25.5,41.5-37.2c14.6-11.7,32-24.2,52.3-37.4c20.3-13.3,42.2-23.9,65.6-31.7C682.9,83.9,706,80,728.6,80c81.7,0,145.6,22.6,192,67.8C966.9,193.1,990,255.8,990,335.9z M920,335.9c0-29.5-3.9-55.6-11.7-78.2c-7.9-22.6-17.9-40.6-30.1-53.8c-12.2-13.3-27.1-24.2-44.6-32.5c-17.6-8.3-34.6-14.1-51.4-16.9s-34.7-4.4-53.6-4.4s-39.4,4.7-61.2,13.9c-21.8,9.3-42,21-60.4,35c-18.4,14.1-34.2,27.2-47.3,39.4c-13.1,12.2-24.1,23.3-32.8,33.6c-6.6,8-15.5,12-26.8,12c-11.3,0-20.3-4-26.8-12c-8.7-10.2-19.6-21.4-32.8-33.7c-13.1-12.2-28.9-25.3-47.3-39.3c-18.4-14.1-38.5-25.7-60.4-35c-21.9-9.3-42.3-14-61.2-14c-19,0-36.9,1.5-53.6,4.4c-16.8,3-33.9,8.6-51.4,17c-17.5,8.4-32.3,19.2-44.6,32.5c-12.3,13.3-22.2,31.3-30.1,53.9C83.9,280.3,80,306.4,80,335.9c0,61.2,34.1,126,102.3,194.2L500,836.3l317.2-305.7C885.7,462.1,920,397.2,920,335.9z' })))], [_createElement('svg', {
                        'version': '1.1',
                        'className': 'icon added icon-solidheart',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                        'x': '0px',
                        'y': '0px',
                        'viewBox': '0 0 1000 1000',
                        'enableBackground': 'new 0 0 1000 1000',
                        'xmlSpace': 'preserve',
                        'key': '27150'
                    }, _createElement('g', {}, _createElement('path', { 'd': 'M990,335.9c0,80.6-41.8,162.6-125.3,246.1L524.1,910.2c-6.6,6.5-14.6,9.8-24.1,9.8c-9.5,0-17.5-3.3-24.1-9.8L134.7,581c-3.7-2.9-8.7-7.7-15-14.2c-6.4-6.6-16.5-18.5-30.4-35.8c-13.9-17.3-26.3-35.1-37.2-53.3s-20.7-40.3-29.3-66.2C14.2,385.5,10,360.4,10,335.9c0-80.2,23.1-142.9,69.5-188.1c46.3-45.2,110.3-67.8,192-67.8c22.6,0,45.7,3.9,69.2,11.7c23.5,7.9,45.4,18.4,65.6,31.7s37.6,25.8,52.2,37.4c14.5,11.7,28.4,24.1,41.5,37.2c13.1-13.1,26.9-25.5,41.5-37.2c14.6-11.7,32-24.2,52.3-37.4c20.3-13.3,42.2-23.9,65.6-31.7C682.9,83.9,706,80,728.6,80c81.7,0,145.6,22.6,192,67.8C966.9,193.1,990,255.8,990,335.9z M920,335.9c0-29.5-3.9-55.6-11.7-78.2c-7.9-22.6-17.9-40.6-30.1-53.8c-12.2-13.3-27.1-24.2-44.6-32.5c-17.6-8.3-34.6-14.1-51.4-16.9s-34.7-4.4-53.6-4.4s-39.4,4.7-61.2,13.9c-21.8,9.3-42,21-60.4,35c-18.4,14.1-34.2,27.2-47.3,39.4c-13.1,12.2-24.1,23.3-32.8,33.6c-6.6,8-15.5,12-26.8,12c-11.3,0-20.3-4-26.8-12c-8.7-10.2-19.6-21.4-32.8-33.7c-13.1-12.2-28.9-25.3-47.3-39.3c-18.4-14.1-38.5-25.7-60.4-35c-21.9-9.3-42.3-14-61.2-14c-19,0-36.9,1.5-53.6,4.4c-16.8,3-33.9,8.6-51.4,17c-17.5,8.4-32.3,19.2-44.6,32.5c-12.3,13.3-22.2,31.3-30.1,53.9C83.9,280.3,80,306.4,80,335.9c0,61.2,34.1,126,102.3,194.2L500,836.3l317.2-305.7C885.7,462.1,920,397.2,920,335.9z' })))]), _createElement('button', {
                    'type': 'button',
                    'aria-label': 'Add to compare',
                    'className': 'cm-link btn--status' + (getCompare?.().includes(this.handle) ? ' active' : ''),
                    'data-product-handle': this.handle,
                    'onClick': e => {
                        updateCompare?.(this.handle);
                        e.target.classList.toggle('active');
                    }
                }, [_createElement('svg', {
                        'version': '1.0',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'icon icon-compare not-added',
                        'width': '512.000000pt',
                        'height': '512.000000pt',
                        'viewBox': '0 0 512.000000 512.000000',
                        'preserveAspectRatio': 'xMidYMid meet',
                        'key': '45590'
                    }, _createElement('g', {
                        'transform': 'translate(0.000000,512.000000) scale(0.100000,-0.100000)',
                        'stroke': 'none'
                    }, _createElement('path', { 'd': 'M3884 5106 c-63 -28 -100 -106 -82 -173 8 -30 57 -86 246 -275 l237 -238 -1615 0 c-1552 0 -1619 -1 -1708 -19 -260 -54 -478 -240 -578 -492 -49\n        -126 -54 -191 -54 -722 0 -470 1 -492 20 -532 23 -48 80 -85 132 -85 42 0 96 28 123 64 19 27 20 45 25 559 5 484 7 536 24 581 59 156 165 261 322 322 47\n        18 111 19 1679 22 l1630 2 -237 -237 c-143 -144 -239 -248 -242 -263 -16 -76 -10 -107 32 -151 41 -44 84 -57 149 -44 33 6 84 53 410 378 401 399 413 414\n        398 494 -6 33 -53 85 -383 417 -212 212 -391 384 -409 392 -40 17 -79 17 -119 0z' }), _createElement('path', { 'd': 'M4605 2563 c-32 -9 -74 -42 -89 -72 -12 -22 -15 -127 -18 -556 -5 -580 -2 -556 -67 -683 -31 -62 -131 -157 -201 -192 -125 -63 -30 -60 -1790\n        -60 l-1604 0 236 238 c144 144 239 247 242 262 16 76 10 107 -32 151 -41 44 -84 57 -149 44 -33 -6 -84 -53 -410 -378 -401 -399 -413 -414 -398 -494 6 -33\n        53 -85 388 -421 407 -408 413 -412 493 -398 47 9 98 59 112 109 21 76 10 92 -246 350 l-237 237 1608 0 c1113 0 1630 3 1679 11 280 43 513 229 617 491 55\n        139 56 150 56 723 0 512 -1 531 -20 564 -11 19 -32 43 -46 53 -27 20 -89 31 -124 21z' })))], [_createElement('svg', {
                        'className': 'icon added icon-checked',
                        'version': '1.1',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'viewBox': '0 0 26 26',
                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                        'enableBackground': 'new 0 0 26 26',
                        'key': '60200'
                    }, _createElement('path', { 'd': 'm.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z' }))]), _createElement('div', { 'className': 'quick-view-button' }, _createElement('a', {
                    'className': 'quick-view cm-link btn--status',
                    'data-handle': this.handle,
                    'data-pavailable': 'true'
                }, [_createElement('svg', {
                        'version': '1.0',
                        'className': 'icon icon-view',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'width': '512.000000pt',
                        'height': '512.000000pt',
                        'viewBox': '0 0 512.000000 512.000000',
                        'preserveAspectRatio': 'xMidYMid meet',
                        'key': '66850'
                    }, _createElement('g', {
                        'transform': 'translate(0.000000,512.000000) scale(0.100000,-0.100000)',
                        'stroke': 'none'
                    }, _createElement('path', { 'd': 'M152 5020 c-18 -11 -41 -34 -52 -52 -19 -32 -20 -52 -20 -768 0 -716 1 -736 20 -768 30 -48 75 -72 140 -72 65 0 110 24 140 72 19 32 20 52 20 543\nl0 510 718 -717 c780 -779 733 -739 832 -725 99 13 157 122 120 224 -6 16 -325 342 -723 741 l-712 712 510 0 c491 0 511 1 543 20 48 30 72 75 72 140 0 65 -24 110 -72 140 -32 19 -52 20 -768 20 -716 0 -736 -1 -768 -20z' }), _createElement('path', { 'd': 'M3432 5020 c-95 -58 -99 -216 -5 -277 25 -17 67 -18 533 -23 l506 -5\n-734 -700 c-404 -385 -743 -713 -753 -730 -26 -41 -25 -129 1 -173 11 -18 34\n-41 52 -52 44 -27 132 -27 173 -1 17 10 363 337 770 726 l740 707 5 -519 c5\n-478 6 -521 23 -546 29 -45 75 -67 137 -67 65 0 110 24 140 72 19 32 20 52 20\n768 0 716 -1 736 -20 768 -11 18 -34 41 -52 52 -32 19 -52 20 -768 20 -716 0\n-736 -1 -768 -20z' }), _createElement('path', { 'd': 'M3042 2143 c-63 -31 -93 -104 -78 -190 7 -34 88 -119 764 -795 l757\n-758 -510 0 c-491 0 -511 -1 -543 -20 -48 -30 -72 -75 -72 -140 0 -65 24 -110\n72 -140 32 -19 52 -20 768 -20 716 0 736 1 768 20 18 11 41 34 52 52 19 32 20\n52 20 768 0 716 -1 736 -20 768 -30 48 -75 72 -140 72 -65 0 -110 -24 -140\n-72 -19 -32 -20 -52 -20 -543 l0 -510 -752 752 c-422 421 -765 757 -781 763\n-41 15 -107 12 -145 -7z' }), _createElement('path', { 'd': 'M1850 2069 c-14 -5 -346 -330 -737 -722 l-713 -712 0 510 c0 491 -1\n511 -20 543 -30 48 -75 72 -140 72 -65 0 -110 -24 -140 -72 -19 -32 -20 -52\n-20 -768 0 -716 1 -736 20 -768 11 -18 34 -41 52 -52 32 -19 52 -20 768 -20\n716 0 736 1 768 20 48 30 72 75 72 140 0 65 -24 110 -72 140 -32 19 -52 20\n-543 20 l-510 0 717 717 c494 495 719 727 724 746 11 46 -1 122 -24 154 -39\n55 -133 79 -202 52z' })))])), this.variant_ids.length === 1 ? _createElement('product-form', {
                    'key': '8619',
                    'class': 'product-form'
                }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'ProductDrop',
                    'acceptCharset': 'UTF-8',
                    'className': 'form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate',
                    'data-type': 'add-to-cart-form'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('div', { 'className': 'product-form__buttons' }, _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'className': 'product-form__submit button',
                    'disabled': this.out_of_stock
                }, [_createElement('svg', {
                        'className': 'icon icon-cart',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'viewBox': '0 0 762.47 673.5',
                        'key': '92640'
                    }, _createElement('path', { 'd': 'M600.86,489.86a91.82,91.82,0,1,0,91.82,91.82A91.81,91.81,0,0,0,600.86,489.86Zm0,142.93a51.12,51.12,0,1,1,51.11-51.11A51.12,51.12,0,0,1,600.82,632.79Z' }), _createElement('path', { 'd': 'M303.75,489.86a91.82,91.82,0,1,0,91.82,91.82A91.82,91.82,0,0,0,303.75,489.86Zm-.05,142.93a51.12,51.12,0,1,1,51.12-51.11A51.11,51.11,0,0,1,303.7,632.79Z' }), _createElement('path', { 'd': 'M392.07,561.33h66.55a20.52,20.52,0,0,1,20.46,20.46h0a20.52,20.52,0,0,1-20.46,20.46H392.07' }), _createElement('path', { 'd': 'M698.19,451.14H205.93a23.11,23.11,0,0,1-23.09-22c0-.86-.09-1.72-.19-2.57l-1.82-16.36H723.51L721.3,428A23.11,23.11,0,0,1,698.19,451.14Z' }), _createElement('path', { 'd': 'M759.15,153.79H246.94l-3.32-24.38a17.25,17.25,0,0,1,17.25-17.26H745.21a17.26,17.26,0,0,1,17.26,17.26Z' }), _createElement('path', { 'd': 'M271.55,345.56l-31.16-208A20.53,20.53,0,0,1,257.13,114h0a20.53,20.53,0,0,1,23.6,16.74l31.16,208a20.52,20.52,0,0,1-16.74,23.59h0A20.52,20.52,0,0,1,271.55,345.56Z' }), _createElement('path', { 'd': 'M676,451.15l48.69-337.74,22.9.07a17.25,17.25,0,0,1,14.55,19.59l-42.1,303.16a17.24,17.24,0,0,1-19.59,14.54Z' }), _createElement('path', { 'd': 'M184.24,436.27,123.7.12l23.72,0a17.26,17.26,0,0,1,19.33,14.92l60.56,436.35-23.74-.25A17.25,17.25,0,0,1,184.24,436.27Z' }), _createElement('path', { 'd': 'M148.38,40.77H20.26A20.32,20.32,0,0,1,0,20.51H0A20.32,20.32,0,0,1,20.26.25H148.38' }))])), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }))) : null, this.variant_ids.length > 1 ? _createElement('a', {
                    'href': this.url,
                    'className': 'product-form__submit button select-button',
                    'title': this.removeHTML(this.title),
                    'key': '10775'
                }, [_createElement('svg', {
                        'className': 'icon icon-select',
                        'width': '50px',
                        'height': '50px',
                        'version': '1.1',
                        'id': 'Capa_1',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                        'x': '0px',
                        'y': '0px',
                        'viewBox': '0 0 512 512',
                        'style': { enableBackground: 'new 0 0 512 512' },
                        'xmlSpace': 'preserve',
                        'key': '109340'
                    }, _createElement('g', {}, _createElement('g', {}, _createElement('path', { 'd': 'M492.703,0H353.126c-10.658,0-19.296,8.638-19.296,19.297c0,10.658,8.638,19.296,19.296,19.296h120.281v120.281\n        c0,10.658,8.638,19.296,19.296,19.296c10.658,0,19.297-8.638,19.297-19.296V19.297C512,8.638,503.362,0,492.703,0z' }))), _createElement('g', {}, _createElement('g', {}, _createElement('path', { 'd': 'M506.346,5.654c-7.538-7.539-19.747-7.539-27.285,0L203.764,280.95c-7.539,7.532-7.539,19.753,0,27.285\n        c3.763,3.769,8.703,5.654,13.643,5.654c4.933,0,9.873-1.885,13.643-5.654L506.346,32.939\n        C513.885,25.407,513.885,13.186,506.346,5.654z' }))), _createElement('g', {}, _createElement('g', {}, _createElement('path', { 'd': 'M427.096,239.92c-10.658,0-19.297,8.638-19.297,19.296v214.191H38.593V104.201h214.191\n        c10.658,0,19.296-8.638,19.296-19.296s-8.638-19.297-19.296-19.297H19.297C8.638,65.608,0,74.246,0,84.905v407.799\n        C0,503.362,8.638,512,19.297,512h407.799c10.664,0,19.296-8.638,19.296-19.297V259.216\n        C446.392,248.558,437.754,239.92,427.096,239.92z' }))), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}), _createElement('g', {}))]) : null)), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'card-information__wrapper' }, _createElement('span', { 'className': 'visually-hidden' }, 'vendor'), _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'caption-with-letter-spacing light'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('span', { 'className': 'card-information__text h5' }, _createElement('a', Object.assign({}, { 'href': this.href }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'price' }, _createElement('dl', {}, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '12821'
                }, _createElement('dt', {}, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Regular price')), _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '13171'
                }, _createElement('dt', {}, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Sale price')), _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price))), _createElement('dt', { 'className': 'price__compare' }, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Regular price')), _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)))) : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []